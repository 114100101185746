import React from 'react';

import './addressText.css';
import MapLinks from '../../../config/MapLinks';

const CLASS_NAME = 'address-text';

class AddressText extends React.Component {
    render() {
        return <div className={CLASS_NAME}>{this._renderText()}</div>;
    }

    _renderText() {
        if (this.props.page === MapLinks.VEDENI) {
            return (
                <>
                    <p>Collo-louky 2151, 738 01 Frýdek-Místek</p>
                    <p>GPS: 49.6723014N, 18.3256594E</p>
                </>
            );
        } else if (this.props.page === MapLinks.PROVOZOVNA) {
            return (
                <>
                    <p>U Řeky 925, 720 00 Ostrava-Hrabová</p>
                    <p>GPS: 49.7814958N, 18.2849506E</p>
                </>
            );
        }

        return null;
    }
}

export default AddressText;
