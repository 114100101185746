import React from 'react';

import './mapEmbed.css';
import MapLinks from '../../../config/MapLinks';

const CLASS_NAME = 'map-embed';

class MapEmbed extends React.Component {
    get managementEmbed() {
        if (this.props.page === MapLinks.VEDENI) {
            return (
                <iframe
                    title={'mapa s cestou k budově vedení společnosti Armostav Místek, s.r.o.'}
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2582.044522848631!2d18.325669!3d49.6723!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4713f6d5e41797cf%3A0x4c230e3bf35a400c!2sCollo-louky%202151%2C%20M%C3%ADstek%2C%20738%2001%20Fr%C3%BDdek-M%C3%ADstek!5e0!3m2!1scs!2scz!4v1626539434215!5m2!1scs!2scz"
                    allowFullScreen=""
                    frameBorder="0"
                    height={this.props.small ? '420' : '500'}
                    loading={'lazy'}
                    style={{ border: 0 }}
                />
            );
        } else if (this.props.page === MapLinks.PROVOZOVNA) {
            return (
                <iframe
                    title={'mapa s cestou k provozovně společnosti Armostav Místek, s.r.o.'}
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2576.2430689077682!2d18.282770215545607!3d49.78149377938868!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4713fb399541a493%3A0xa4b4c4b87ca5d7d0!2sU%20%C5%98eky%20925%2C%20720%2000%20Ostrava-Hrabov%C3%A1!5e0!3m2!1scs!2scz!4v1616865827938!5m2!1scs!2scz"
                    allowFullScreen=""
                    frameBorder="0"
                    height={this.props.small ? '420' : '500'}
                    loading={'lazy'}
                    style={{ border: 0 }}
                />
            );
        } else if (this.props.page === MapLinks.VEDENI_STREET) {
            return (
                <iframe
                    title={'pohled na budovu vedení společnosti Armostav Místek, s.r.o.'}
                    src="https://www.google.com/maps/embed?pb=!4v1626511682161!6m8!1m7!1siY2uWdhF-XBJPWlKtHQn9w!2m2!1d49.67217617837809!2d18.32638394172038!3f40.45916763711745!4f-5.10013290771353!5f0.7820865974627469&language=CZECH"
                    allowFullScreen=""
                    frameBorder="0"
                    height={this.props.small ? '420' : '500'}
                    loading={'lazy'}
                    style={{ border: 0 }}
                />
            );
        }

        return null;
    }

    render() {
        return <div className={CLASS_NAME}>{this.managementEmbed}</div>;
    }
}

export default MapEmbed;
