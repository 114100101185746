import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import React from 'react';
import AddressText from '../addressText/AddressText';
import MapEmbed from '../mapEmbed/MapEmbed';
import './mapPageContent.css';
import MapLinks from '../../../config/MapLinks';
import EmptySpace from '../../emptySpace/EmptySpace';

const CLASS_NAME = 'map-page-content';

class MapPageContent extends React.Component {
    get currentPage() {
        const pathname = this.props.location || 'vedeni';

        const mapRoutes = Object.values(MapLinks);
        return mapRoutes.find(route => pathname.includes(route));
    }

    render() {
        const { currentPage } = this;

        return (
            <div className={CLASS_NAME}>
                <div className={`${CLASS_NAME}__items-wrapper`}>
                    <h3>Provozovna</h3>
                    <div className={`${CLASS_NAME}__adress-block`}>
                        <AddressText page={MapLinks.PROVOZOVNA} />
                        <div className={`${CLASS_NAME}__way-title`}>Cesty k provozovně:</div>
                    </div>
                    <div className={`${CLASS_NAME}__adress-block`}>
                        <MapEmbed page={MapLinks.PROVOZOVNA} small={true} />
                        {this._renderWayToFactory()}
                    </div>
                    <EmptySpace height={70} />
                    <h3>Vedení společnosti</h3>
                    <div className={`${CLASS_NAME}__adress-block`}>
                        <AddressText page={MapLinks.VEDENI} />
                        <div className={`${CLASS_NAME}__way-title`}>
                            Budova sídla naší společnosti:
                        </div>
                    </div>
                    <div className={`${CLASS_NAME}__adress-block`}>
                        <MapEmbed page={MapLinks.VEDENI} small={true} />
                        {this._renderHeadquarterImage()}
                    </div>
                </div>
            </div>
        );
    }

    _renderHeadquarterImage() {
        return (
            <div className={`${CLASS_NAME}__headquarter`}>
                {/*                <EmptySpace height={50} />
                <p className={`${CLASS_NAME}__additional-info-title`}>
                    Budova sídla naší společnosti:
                </p>
                <EmptySpace height={10} />*/}
                <MapEmbed page={MapLinks.VEDENI_STREET} small={true} />
            </div>
        );
    }

    _renderWayToFactory() {
        return (
            <div className={`${CLASS_NAME}__headquarter`}>
                {/*                <EmptySpace height={50} />
                <p className={`${CLASS_NAME}__additional-info-title`}>Cesty k provozovně:</p>
                <EmptySpace height={10} />*/}
                <ImageGallery
                    showPlayButton={false}
                    lazyLoad={true}
                    items={[
                        {
                            original: '/images/routes/fm600.jpg',
                            thumbnail: '/images/routes/fm200.jpg',
                            fullscreen: '/images/routes/fm.jpg',
                            thumbnailTitle: 'cesta od FM',
                            thumbnailLabel: 'cesta od FM',
                            originalTitle: 'cesta od FM',
                            description: 'cesta od FM',
                        },
                        {
                            original: '/images/routes/pribor600.jpg',
                            thumbnail: '/images/routes/pribor200.jpg',
                            fullscreen: '/images/routes/pribor.jpg',
                            thumbnailTitle: 'cesta od Příboru',
                            thumbnailLabel: 'cesta od Příboru',
                            originalTitle: 'cesta od Příboru',
                            description: 'cesta od Příboru',
                        },
                        {
                            original: '/images/routes/opava600.jpg',
                            thumbnail: '/images/routes/opava200.jpg',
                            fullscreen: '/images/routes/opava.jpg',
                            thumbnailTitle: 'cesta od Opavy',
                            thumbnailLabel: 'cesta od Opavy',
                            originalTitle: 'cesta od Opavy',
                            description: 'cesta od Opavy',
                        },
                    ]}
                />
            </div>
        );
    }
}

export default MapPageContent;
