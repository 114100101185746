import React from 'react';
import EmptySpace from '../../emptySpace/EmptySpace';
import './mapPage.css';
import MapPageContent from '../mapPageContent/MapPageContent';
import RoutesLinks from '../../../config/RoutesLinks';
import PageTitleLine from '../../pageTitleLine/PageTitleLine';
import EmptySpaceBigScreen from "../../emptySpaceBigScreen/EmptySpaceBigScreen"

export default function mapPage(subRoute) {
    return (
        <>
            <PageTitleLine idTag={RoutesLinks.MAPA} title={'Cesta k nám'} />
            <EmptySpace height={60} />
            <MapPageContent location={`${RoutesLinks.MAPA}/${subRoute}`} />
            <EmptySpace height={100} />
          <EmptySpaceBigScreen height={60} />
        </>
    );
}
